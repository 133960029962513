import React from 'react'
import { formatDateRange } from '../common/dateFunctions'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'

const ScheduleCompact = ({ schedules, className }) => {
  const data = useStaticQuery(graphql`
      query {
          allLocationsJson {
              nodes {
                  id
                  key
                  preposition
                  name
              }
          }
      }
  `)

  return schedules.map((schedule, index) => {
      const location = data.allLocationsJson.nodes.find(loc => loc.key === schedule.locationKey)
      const locationAttributation = location ? location.preposition + ' ' + location.name : ''
      return (
        <div key={index}>
          <h5 className={classNames(className, 'mb-1 font-weight-normal')}>
            {formatDateRange(new Date(schedule.startDate), new Date(schedule.endDate))}
          </h5>
          <p className={classNames(className, 'pb-4')}>
            {schedule.category} „{schedule.title}“ {locationAttributation}
          </p>
        </div>
      )
    },
  )
}

export default ScheduleCompact
