import PropTypes from 'prop-types'
import React from 'react'
import CardDeck from './CardDeck'
import Card from './Card'

const ContactCard = ({ title, teaser, bold = false }) => {
  const _title = title ? title : 'Ins Gespräch kommen'
  const _teaser = teaser ? teaser : 'Wir können Ihnen weiterhelfen? Sie haben eine Frage oder benötigen mehr Informationen? Dann findet Ihr ' +
    'Anliegen über eine der nachfolgend aufgeführten Möglichkeiten den Weg zu uns.'
  return (
    <CardDeck>
      <Card>
        {bold ?
          <h1 className="display-title home-title" style={{ 'font-size': '3.285em' }}>{_title}</h1>
          :
          <h3>{_title}</h3>
        }
        <p className="mt-5">{_teaser}</p>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <h5 className="display-decor">Kontakt</h5>
            <p>
                  <span>Telefon: <a className="phone-link" aria-label={'Telefonnummer BUSCHMAIS GbR'}
                                    href="tel:+493513209230">+49 351 3209230</a></span>
              <br/><span>Fax: <a className="phone-link" aria-label={'Faxnummer BUSCHMAIS GbR'}
                                 href="tel:+4935132092329">+49 351 32092329</a></span>
              <br/><span>E-Mail: <a className="phone-link" href="mailto:info@buschmais.com"
                                    aria-label={'E-Mail-Kontakt BUSCHMAIS'}>info@buschmais.com</a> </span>
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <h5 className="display-decor">Postanschrift</h5>
            <p>
              <span>BUSCHMAIS GbR</span>
              <br/><span>Leipziger Str. 93</span>
              <br/><span>01127 Dresden</span>
            </p>
          </div>
        </div>
      </Card>
    </CardDeck>
  )
}

export default ContactCard

ContactCard.propTypes = {
  bold: PropTypes.bool,
  teaser: PropTypes.string,
  title: PropTypes.string,
}

ContactCard.defaultProps = {
  bold: false,
}
