import React from 'react'
import FullscreenLandingSection from '../components/FullscreenLandingSection'
import Seo from '../components/Seo'
import NavigationLink from '../components/NavigationLink'
import TeaserSection from '../components/TeaserSection'
import TeaserHeader from '../components/TeaserHeader'
import { graphql } from 'gatsby'
import TeaserBody from '../components/TeaserBody'
import List from '../components/List'
import ListItem from '../components/ListItem'
import SliderSection from '../components/SliderSection'
import Slide from '../components/Slide'
import CardDeck from '../components/CardDeck'
import NewsCard from '../components/NewsCard'
import ScheduleCompact from '../components/ScheduleCompact'
import ContactCard from '../components/ContactCard'
import DefaultSection from '../components/DefaultSection'


const Page = ({ data }) => {
  const now = new Date()
  const schedules = data.allTermineJson.edges
    .filter(scheduleNode => new Date(scheduleNode.schedule.endDate) >= now)
    .slice(0, 3)
    .map(scheduleNode => scheduleNode.schedule)

  return (
    <React.Fragment>
      <Seo />

      <FullscreenLandingSection imageRelativePath={'Titelbild_Startseite.jpg'} backgroundColor={'#ffffff'}>
        <div className="row">
          <div className="col-12 col-md-8 text-left">
            <div className="title-desc mb-0">
              <h1 className="home-title display-title mb-4">
                <span className="text-stroke">Technologie</span>
                <br /><span>Beratung</span>
                <br /><span className="text-stroke">Innovation</span>
              </h1>
              <p className="intro">Wir hören zu, verstehen und erarbeiten nachhaltige Lösungen.</p>
            </div>
            <div className="btns-action">
              <NavigationLink to="#angebot">Was wir tun</NavigationLink>
            </div>
          </div>
        </div>
      </FullscreenLandingSection>

      <TeaserSection anchor="angebot">
        <TeaserHeader hasBody title="Unser Angebot">
          <p>Wir sind ein Beratungsunternehmen, das IT-gestützte Prozesse mit dem Kunden analysiert,
            plant, optimiert und ihn bei der Umsetzung neuer Anforderungen unterstützt.</p>
          <p><strong>Wir sind technologisch kompetent, innovativ und lösungsorientiert.</strong></p>
        </TeaserHeader>
        <TeaserBody>
          <List>
            <ListItem title="Architektur" linkTo="/architektur/" linkText="Details">
              <p className="text-black-50">
                Die Architektur eines Software-Systems bestimmt maßgeblich wichtige Qualitätseigenschaften. Wir helfen
                Ihnen, die richtigen Entscheidungen zur richtigen Zeit zu fällen.
              </p>
            </ListItem>
            <ListItem title="Web" linkTo="/web/" linkText="Details">
              <p className="text-black-50">
                Mit unseren Kunden heben wir Potenziale durch die Digitalisierung von Geschäftsprozessen.
                Unser Anspruch: Zukunftsorientierte Lösungen durch den gezielten Einsatz moderner
                Technologien.
              </p>
            </ListItem>
            <ListItem title="Enterprise Java" linkTo="/enterprise-java/" linkText="Details">
              <p className="text-black-50">
                Viele Geschäftsprozesse werden durch Anwendungssysteme getragen, die auf Enterprise-Java-Technologien
                basieren. Wir unterstützen Sie in der Wartung und Weiterentwicklung dieser Systeme.
              </p>
            </ListItem>
          </List>
        </TeaserBody>
      </TeaserSection>
      <SliderSection menuInverted={true} logoInverted={true}>
        <Slide category="Case Study" title="Interdiscount" keywords="Web, Cloud, Automatisierung"
               linkTo="/referenzen/interdiscount-probot/"
               linkTitle="Zur Referenz" className="bg-gradient-brand-1">
          <p>
            Interdiscount, als Nummer 1 des Schweizer Heim- und Unterhaltungselektronik-Marktes, setzt beim Ausbau der
            Online-Aktivitäten und der Erweiterungen des Produktmanagements auf die Unterstützung von BUSCHMAIS.
          </p>
        </Slide>

        <Slide category="Case Study" title="Bundesagentur für Arbeit" keywords="Qualität, Wartbarkeit"
               linkTo="/referenzen/arbeitsagentur/"
               linkTitle="Zur Referenz" className="bg-gradient-brand-2">
          <p>Bei der Bundesagentur für Arbeit ermöglicht unser Open-Source-Tool jQAssistant <strong>kontinuierliche
            Transparenz </strong>
            und Kontrolle über die Architektur komplexer IT-Systeme und somit ihre <strong>langfristige
              Wartbarkeit</strong>.</p>
        </Slide>

        <Slide category="Case Study" title="One Data GmbH" keywords="Architektur, Modernisierung"
               linkTo="/referenzen/one-data/"
               linkTitle="Zur Referenz" className="bg-gradient-brand-3">
          <p>Mit der Hilfe von jQAssistant haben wir für die ONE DATA Plattform der One Data GmbH eine <strong>moderne
            Softwarearchitektur</strong> entworfen, sowie den <strong>Prozess der Modernisierung</strong> geplant und
            begleitet.</p>
        </Slide>

        <Slide category="Case Study" title="E-Post Development GmbH" keywords="Architektur, Qualität"
               linkTo="/referenzen/epost-jqa/" linkTitle="Zur Referenz" className="bg-gradient-brand-1">
          <p>
            Unser Open-Source-Framework jQAssistant hilft dabei, die Qualität der Microservice Architektur der
            E-Post Development GmbH abzusichern. Lesen Sie mehr in der
            Case-Study: <strong>Erhöhte Softwarequalität und Effizienz durch kontinuierliche Architekturvalidierung bei
            der E-Post Development
            GmbH</strong>
          </p>
        </Slide>

        <Slide category="Case Study" title={'ThyssenKrupp Steel Europe AG'} keywords={'Industrie, Enterprise Java'}
               linkTo="/referenzen/tks-fls/" linkTitle={'Zur Referenz'} className="bg-gradient-brand-3">
          <p>
            Wir unterstützten die ThyssenKrupp Steel Europe AG bei der <strong>Erstellung eines neuen</strong>, für alle
            Standorte einheitlichen, <strong>Fertigungsleitsystems</strong>. Wir standen bei der Technologieauswahl zur
            Seite, migrierten die bestehende Persistenztechnologie und schufen darauf aufbauend eine tragfähige
            Persistenzstrategie.
          </p>
        </Slide>
      </SliderSection>

      <TeaserSection>
        <TeaserHeader title={'Aktuelles'} hasBody />
        <TeaserBody>
          <CardDeck>
            {!(schedules === null || schedules === undefined) && schedules.length > 0 &&
              <NewsCard title="Unsere nächsten Veranstaltungen" categoryTitle={'Termine'}
                        linkTo={'/termine/'} linkText={'Alle Termine'}>
                <ScheduleCompact schedules={schedules} className="card-text text-muted text-narrow" />
              </NewsCard>
            }

            <NewsCard title="Artikelserie im Java Magazin"
                      categoryTitle={'Fachartikel'}
                      linkTo={'/download/JavaMagazin_Artikelserie_jQAssistant.pdf'}
                      linkAriaLabel={'Link zum PDF-Download unserer Artikelserie im Java Magazin'}
                      linkText={'Zum Artikel'}>
              <p className="card-text text-muted text-narrow">
                Unser Kollege Stephan Pirnbaum hat im Java Magazin eine dreiteilige Artikelserie zum Open-Source-Tool
                jQAssistant
                veröffentlicht. Hier geht er u. a. näher auf das Thema Software Analytics ein und zeigt, wie man mit
                Hilfe von jQAssistant
                und jMolecules lebendige Dokumentation erstellen und Architektur validieren kann. Erfahren Sie mehr dazu
                in seinem Artikel!
              </p>
            </NewsCard>

            <NewsCard title="Case Study: Unser Projekt bei der Bundesagentur für Arbeit"
                      categoryTitle={'Kundenreferenz'}
                      linkTo={'/referenzen/arbeitsagentur/'}
                      linkAriaLabel={'Lesen Sie mehr über die Case Study über das Projekt bei der Bundesagentur für Arbeit'}
                      linkText={'Zur Case Study'}>
              <p className="card-text text-muted text-narrow">
                Erfahren Sie, wie bei der Bundesagentur für Arbeit kontinuierliche Transparenz und Kontrolle über die
                Architektur komplexer IT-Systeme und somit ihre langfristige Wartbarkeit mit der Hilfe von jQAssistant
                sichergestellt
                wird. Lesen Sie mehr dazu in unserer neuesten Case Study!
              </p>
            </NewsCard>
          </CardDeck>
        </TeaserBody>
      </TeaserSection>
      <TeaserSection className="bg-level-1">
        <TeaserHeader title="Wir in aller Kürze" linkTitle="Über uns" linkTo="/unternehmen/">
          <p>Die BUSCHMAIS GbR ist ein Dresdner IT-Beratungsunternehmen, gegründet im Jahre 2008. Unsere Schwerpunkte
            liegen in der Architekturberatung und der Entwicklung moderner Geschäftsanwendungen.</p>
          <p>
            Gemeinsam mit unseren Kunden analysieren, planen und optimieren wir IT-gestützte Prozesse und unterstützen
            sie bei der Umsetzung neuer Anforderungen. Dabei arbeiten wir branchenunabhängig und technologiefokussiert
            in
            effizienten Teams.</p>
        </TeaserHeader>
      </TeaserSection>
      <DefaultSection>
        <ContactCard />
      </DefaultSection>
    </React.Fragment>
  )
}

export default Page

export const query = graphql`
    {
        allTermineJson(sort: {fields: startDate}) {
            edges {
                schedule: node {
                    id
                    title
                    category
                    startDate
                    endDate
                    locationKey
                }
            }
        }
    }
`
