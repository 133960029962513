import * as PropTypes from 'prop-types'
import React from 'react'
import Section from './Section'
import SectionWrapper from './SectionWrapper'
import BackgroundAwareness from './BackgroundAwareness'
import Slider from './Slider'

const SliderSection = ({ logoInverted, menuInverted, children }) => {
  return (
    <BackgroundAwareness menuInverted={menuInverted} logoInverted={logoInverted}>
      <Section className="section-slider content-white">
        <SectionWrapper className="fullwidth">
          <Slider children={children}/>
        </SectionWrapper>
      </Section>
    </BackgroundAwareness>
  )
}

export default SliderSection

SliderSection.propTypes = {
  children: PropTypes.node,
  logoInverted: PropTypes.bool,
  menuInverted: PropTypes.bool,
}

SliderSection.defaultProps = {
  logoInverted: false,
  menuInverted: false,
}
