import PropTypes from 'prop-types'
import React from 'react'
import NavigationLink from './NavigationLink'

const NewsCard = ({ title, categoryTitle, linkTo, linkText, children }) => (
  <div className={'mb-5 col-sm-6 col-lg-4'}>
    <div className="card border-0 bg-level-0 h-100">

      <div className="card-header bg-brand">
        <div className="text-white d-flex flex-row align-items-center">
          <span className="h5 m-0 p-0">{categoryTitle}</span>
        </div>
      </div>
      <div className="card-body">
        {title &&
        <h5 className="mb-4">{title}</h5>
        }
        {children}
      </div>
      <div className="card-footer border-0 bg-level-0 mb-3">
        {linkTo &&
        <NavigationLink to={linkTo}
                        ariaLabel={'Weiterführende Informationen' + (title ? (' zu ' + title) : '')}>{linkText || 'Details'}</NavigationLink>
        }
      </div>
    </div>
  </div>
)

export default NewsCard

NewsCard.propTypes = {
  children: PropTypes.node,
  categoryTitle: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
  title: PropTypes.string.isRequired,
}
