import React from 'react'
import classNames from 'classnames'
import NavigationLink from './NavigationLink'

const Slide = ({ category, title, keywords, linkTo, linkTitle, className, children }) => (
  <div className={classNames('media-slide-item-alpha', className)}>
    <div className="media-content">
      <div className="row">
        <div className="col col-12 col-md-5 col-lg-5">
          <div className="media-body body-margin-right">
            {category &&
            <h4 className="text-condensed">{category}</h4>
            }
            <h3 className={'display-5' + (linkTo !== undefined ? ' mb-2' : ' mb-5')}>{title}</h3>
            {keywords &&
            <p className="text-condensed">{keywords}</p>
            }
            {linkTo && linkTitle &&
            <div className="d-none d-md-block btns-action mb-3">
              <NavigationLink to={linkTo}>{linkTitle}</NavigationLink>
            </div>
            }
          </div>
        </div>
        <div className="col col-12 col-md-7 col-lg-7 pl-md-5 ">
          <div className="media-body centered">
            {children}
            {linkTo && linkTitle &&
            <div className="d-md-none btns-action mb-3">
              <NavigationLink to={linkTo}>{linkTitle}</NavigationLink>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Slide
